// @flow

import React from "react";
import { DashboardLayout, Layout, withPrivateRoute } from "@containers";
import Assignments from "@components/Shared/Assignments";

/**
 * Home Page
 */
const MyAssignments = (props: *) => (
    <Layout {...props}>
        <DashboardLayout assignmentsType="user">
            <Assignments assignmentsType="user" location={props.location} />
        </DashboardLayout>
    </Layout>
);

export default withPrivateRoute(MyAssignments);
